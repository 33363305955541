<template>
  <div class="setting-account">
    <van-cell v-for="item in menu" :key="item.id" :title="item.title" :is-link="item.isLink" :value="item.value" :to="item.to" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      menu: []
    }
  },
  computed: {
    ...mapGetters([
      'userInfos'
    ])
  },
  mounted() {
    this.menu = [
      { id: 1, title: '帐号与安全', value: '已保护', isLink: true, to: 'settingAccount' },
      { id: 2, title: '帮助与建议', isLink: true, to: 'settingFeedback' },
      { id: 3, title: '关于猫嘀嘀', isLink: true, to: 'settingAbout' }
    ]
  }

}
</script>

<style lang="scss" scoped>
.setting-account {
  min-height: 100%;
  padding-top: 20px;
  box-sizing: border-box;
  background: #f7f8fa;
}
</style>
